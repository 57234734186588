import { useContext, useEffect, useRef, useState } from "react";
import { throttle } from "lodash";
import { AppContext } from "../contexts/AppContext";

/**
 * calculated rotation for element to "observe" mouse, throttled at 60fps
 * @returns rotation in radians
 */
export function useMouseObserver(elementRef: React.RefObject<SVGPathElement>) {
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const { isMobile } = useContext(AppContext);

  // throttle setMousePosition calls to 60 per second
  const setMousePositionThrottled = useRef(throttle((x, y) => setMousePosition({ x, y }), 1000 / 60));

  // calculate rotation when mousePosition changes
  useEffect(() => {
    const elementRect = elementRef.current?.getBoundingClientRect();
    if (!elementRect) return;

    setRotation(
      Math.atan2(
        mousePosition.y - (elementRect.top + elementRect.width / 2),
        mousePosition.x - (elementRect.left + elementRect.height / 2)
      )
    );
    // eslint-disable-next-line
  }, [mousePosition]);

  useEffect(() => {
    if (isMobile) return;

    window.addEventListener("mousemove", (e) => {
      setMousePositionThrottled.current(e.clientX, e.clientY);
    });
  }, [isMobile]);

  return rotation;
}
