import React from "react";
import { Helmet } from "react-helmet";
import { useSiteMetadata } from "./SiteMetadata";
import "../styles/index.scss";
import { AppContextProvider } from "../contexts/AppContext";
import { Footer } from "./Footer";

export const PageLayout: React.FC = ({ children }) => {
  const { title, description } = useSiteMetadata();

  return (
    <AppContextProvider>
      <div>
        <Helmet>
          <title>{title}</title>
          <meta name="description" content={description} />
        </Helmet>
        {children}
        <Footer />
      </div>
    </AppContextProvider>
  );
};
