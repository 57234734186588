import React from "react";
import { ContactForm } from "./ContactForm";
import FB_LOGO from "../img/fb_logo.svg";
import IG_LOGO from "../img/ig_logo.svg";
import TWITTER_LOGO from "../img/twitter_logo.svg";
import DISCORD_BTN from "../img/discord_btn.svg";
import SPONSORS from "../img/footer_logolink.svg";

export const Footer = () => {
  return (
    <div className="footer" id="contact">
      <div className="container">
        <h2 className="footer-header section-header">kontakt</h2>
      </div>
      <div className="contact">
        <div className="container-narrow">
          <div className="contact-header">
            <h3>Popovídej si s námi</h3>
          </div>
          <div className="discord">
            <a href="https://discord.gg/Xz6zakM" target="_blank" rel="noreferrer">
              <DISCORD_BTN />
            </a>
          </div>
          <div className="contact-form-header">
            <h3>Napiš nám</h3>
          </div>
          <ContactForm />
          {/* <div className="socials">
            <a href="https://facebook.com" target="_blank" rel="noreferrer">
              <FB_LOGO />
            </a>
            <a href="https://instagram.com" target="_blank" rel="noreferrer">
              <IG_LOGO />
            </a>
            <a href="https://twitter.com" target="_blank" rel="noreferrer">
              <TWITTER_LOGO />
            </a>
          </div> */}
          <div className="contact-info">
            <div className="contact-info-r">
              <a href="mailto:jasne@chcidelathry.cz">
                <span>jasne@chcidelathry.cz</span>
              </a>
              {/* <span>+420 000 111 222</span> */}
            </div>
            <div className="contact-info-l">
              <span>
                Chci dělat hry? je platformou Centra herního vzdělávání, součástí projektu iKAP JMK II.
              </span>
              <span>Projekt je spolufinancován Evropskou unií.</span>
              <span>
                Tato stránka je licencována pod{" "}
                <a
                  className="licence-link"
                  href="http://creativecommons.org/licenses/by/4.0/?ref=chooser-v1"
                  target="_blank"
                  rel="license noopener noreferrer"
                >
                  CC BY 4.0
                  <img
                    className="licence-icon"
                    src="https://mirrors.creativecommons.org/presskit/icons/cc.svg?ref=chooser-v1"
                  />
                  <img
                    className="licence-icon"
                    src="https://mirrors.creativecommons.org/presskit/icons/by.svg?ref=chooser-v1"
                  />
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="sponsors">
        <div className="container-narrow">
          <SPONSORS />
        </div>
      </div>
    </div>
  );
};
