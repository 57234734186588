import React, { useEffect, useRef, useState } from "react";
import { Link } from "gatsby";
import scrollToEl from "gatsby-plugin-smoothscroll";
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";
import Logo from "../img/Logo_CHDH.svg";

interface MenuProps {
  isHome: boolean;
}

export const Menu: React.FC<MenuProps> = ({ isHome }) => {
  const [open, setOpen] = useState(false);
  const TRANSITION_TIME = 400;
  const menuElement = useRef<HTMLDivElement>(null);

  const closeMenu = () => {
    enableBodyScroll(menuElement.current!);
    setOpen(false);
  };

  const waitForMenuClose = (): Promise<void> => {
    return new Promise((res) => {
      closeMenu();
      setTimeout(() => res(), TRANSITION_TIME);
    });
  };

  useEffect(() => {
    // run when component is unmounted
    clearAllBodyScrollLocks();
  }, []);

  return (
    <>
      <div className="menu-icon-container">
        <div className={`container ${!isHome ? "landing-sm" : ""}`}>
          {!isHome && (
            <Link to="/" className="header-logo">
              <Logo />
            </Link>
          )}
          <div
            className="menu-icon"
            onClick={() => {
              setOpen(true);
              disableBodyScroll(menuElement.current!);
            }}
          >
            <span />
            <span />
            <span />
          </div>
        </div>
      </div>
      <div className={`menu ${open ? "open" : ""}`} ref={menuElement}>
        <div className="container">
          <div className="menu-top">
            {isHome ? (
              <>
                <div
                  className="menu-top-link"
                  onClick={() => waitForMenuClose().then(() => scrollToEl("#events"))}
                >
                  události
                </div>
                <div className="menu-top-link">
                  <Link to="/blog">aktuality</Link>
                </div>
                <div
                  className="menu-top-link"
                  onClick={() => waitForMenuClose().then(() => scrollToEl("#contact"))}
                >
                  kontakt
                </div>
              </>
            ) : (
              <>
                <div className="menu-top-link">
                  <Link title="události" to="/#events">
                    události
                  </Link>
                </div>
                <div className="menu-top-link">
                  <Link to="/blog" onClick={() => window.location.pathname === "/blog" && waitForMenuClose()}>
                    aktuality
                  </Link>
                </div>
                <div className="menu-top-link">
                  <Link to="/#contact">kontakt</Link>
                </div>
              </>
            )}
            <div className="menu-icon open" onClick={() => waitForMenuClose()}>
              <span />
              <span />
              <span />
            </div>
          </div>
          <div className="menu-center">
            <div className="menu-center-link">
              <Link to="/stredni-skoly">střední školy</Link>
            </div>
            <div className="menu-center-link">
              <Link to="/kurzy-a-kluby">kurzy a kluby</Link>
            </div>
            <div className="menu-center-link">
              <Link to="/jak-zacit-doma">jak začít doma</Link>
            </div>
            <div className="menu-center-link">
              <Link to="/vyssi-odborne-a-vysoke-skoly"> vyšší odborné a vysoké školy</Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
