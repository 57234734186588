import React from "react";
import { useMediaQuery } from "react-responsive";

export const AppContext = React.createContext({ isMobile: false, isTablet: false, isLaptop: false });

export const AppContextProvider: React.FC = ({ children }) => {
  const isMobile = useMediaQuery({ query: "(max-width: 640px)" });
  const isTablet = useMediaQuery({ query: "(max-width: 1200px)" });
  const isLaptop = useMediaQuery({ query: "(max-width: 1450px)" });

  return <AppContext.Provider value={{ isMobile, isLaptop, isTablet }}>{children}</AppContext.Provider>;
};
