import React, { useContext } from "react";
import scrollTo from "gatsby-plugin-smoothscroll";
import Arrow from "../img/arrow.svg";
import { Menu } from "./Menu";
import { AppContext } from "../contexts/AppContext";
import { FluidObject } from "gatsby-image";
import { MouseObserverLogo } from "./MouseObserverLogo";

export const Landing = () => {
  const { isMobile } = useContext(AppContext);

  return (
    <div className="landing">
      <Menu isHome />
      <div className="landing-logo mouse-observer">
        <MouseObserverLogo />
      </div>
      <div className="landing-arrow" onClick={() => scrollTo(isMobile ? "#hero" : "#content-menu")}>
        <Arrow />
      </div>
    </div>
  );
};

interface LandingSmallProps {
  name: string;
}

export const LandingSmall: React.FC<LandingSmallProps> = ({ name }) => {
  return (
    <div className="landing small">
      <Menu isHome={false} />
      <div className="landing-header">
        <div className="container-narrow">
          <h1>{name}</h1>
        </div>
      </div>
    </div>
  );
};

interface LandingCategoryProps {
  name: string;
  image: FluidObject;
}

export const LandingCategory: React.FC<LandingCategoryProps> = ({ image, name }) => (
  <div className="landing small">
    <Menu isHome={false} />
    <div className="landing-header">
      <div className="container-narrow">
        <h1>{name}</h1>
      </div>
    </div>
  </div>
);
